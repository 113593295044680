<template>
  <div class="text-center" style="padding-top: 5em; padding-bottom: 5em">
    <i class="fas fa-frown fa-10x" style="color: #7a9c4e; padding-bottom: .1em"></i>
    <h1>404</h1>
    <h4>Page Not Found</h4>
    <p>The Page you are looking for doesn't exist or another error occurred.
      <br><b>Go back</b>, or head over to <router-link :to=" { name: 'Home' } ">rauchholzlibrary.org</router-link> to choose a new path.
    </p>
  </div>
</template>

<script>
export default {
  name: "PageNotFound.vue"
}
</script>

<style scoped>

</style>
